<template>
    <div>
        <div class="blue-cont">
            <div class="top-title">
                <div class="name">基础信息</div>
            </div>
        </div>
        <div class="complete-container">
            <router-view />
            <transition>
                <div v-show="gotop" class="gotoTop" @click="toTop"></div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompleteInfo",
    data() {
        return {
            gotop: false,
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
        handleScroll() {
            let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
            scrolltop > 200 ? (this.gotop = true) : (this.gotop = false);
        },
        toTop() {
            document.getElementsByTagName('html')[0].scrollIntoView({
                block: "start",
                behavior: "smooth",
            })
        },
    },
}
</script>

<style lang="less" scoped>
.blue-cont {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
    .top-title {
        width: 960px;
        height: 130px;
        margin: 0 auto;
    }
    .name {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        padding-top: 46px;
    }
}

.complete-container {
    width: 960px;
    margin: 40px auto;
    color: #606266;
    font-size: 14px;
}

// gotoTop
.gotoTop {
    position: fixed;
    z-index: 1500;
    bottom: 10px;
    border-radius: 4px;
    right: 10px;
    background: #666f8e url('../../assets/img/goTop.png') 0 0 no-repeat;
    width: 54px;
    height: 54px;
    cursor: pointer;
    opacity: 0.6;
}
.gotoTop:hover {
    opacity: 1;
}
.v-enter {
    opacity: 0;
}
.v-enter-active {
    transition: all 1s;
}
.v-enter-to {
    opacity: 0.6;
}
</style>
